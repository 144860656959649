import router from "@/router";
import {onBeforeMount} from "vue";

export const routesNavbar = () => router.getRoutes().filter((page) => page.meta.menu === 'navbar')

export const setTitle = () => {
    // const routesNavbar = router.getRoutes().filter((page) => page.meta.menu === 'navbar')

    onBeforeMount(function () {
        let page = router.getRoutes().find(function (route) {
            return route.path === router.currentRoute.value.path
        })
        document.title = page?.meta?.title ?? '404';
    })
}
