import axios from "axios";

export class CitiesClass {

    options

    constructor() {
        this.options = this.getApi()
    }

    async getApi() {
        return (await axios('branches/cities')).data;
    }


}
