<script setup>
import TitleBlock from "@/components/TitleBlock.vue";
import {setTitle} from "@/services/set-title";
import {NewsClass} from "@/api/NewsClass";
import {onBeforeMount, ref} from "vue";

setTitle()

let News = ref()


onBeforeMount(async () => {
  News.value = await new NewsClass().news
  console.log(News)
})


</script>

<template>

  <div class="lg:p-12 p-6">

    <TitleBlock>
      Статьи
    </TitleBlock>


    <div class="flex justify-center">


      <div class="space-y-12">

        <div class="grid grid-cols-1 xl:grid-cols-2 gap-6 mt-6">


          <div v-for="(news, key) in News" :key="key"
               class="rounded-xl shadow-lg p-8 hover text-gray-600 bg-base-100 content-start">

            <div class="font-semibold text-info text-lg" v-html="news.title">
            </div>

            <hr class="my-3">

            <div class="" v-html="news.text">
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>


