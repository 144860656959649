import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ContactView from "@/views/ContactView.vue";
import BranchesView from "@/views/BranchesView.vue";
import FaqView from "@/views/FaqView.vue";
import JobsView from "@/views/JobsView.vue";
import NewsView from "@/views/NewsView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

const routes = [
    {
        path: '/',
        name: 'Главная',
        meta: {
            menu: 'navbar',
            title: 'Художественная гимнастика для детей в Минске | Школа гимнастики для девочек'
        },
        params: true,
        component: HomeView
    },
    {
        path: '/branches',
        name: 'Филиалы',
        meta: {
            menu: 'navbar',
            title: 'Адреса школ гимнастики для девочек, расписание клуба Принцесса спорта'
        },
        params: true,
        component: BranchesView
    },
    {
        path: '/faq',
        name: 'Частые вопросы',
        meta: {
            menu: 'navbar',
            title: 'Принцесса спорта гимнастический клуб – Частые вопросы'
        },
        params: true,
        component: FaqView
    },
    {
        path: '/jobs',
        name: 'Вакансии',
        meta: {
            menu: 'navbar',
            title: 'Принцесса спорта гимнастический клуб – Вакансии'
        },
        params: true,
        component: JobsView
    },
    {
        path: '/contact',
        name: 'Контакты',
        meta: {
            menu: 'navbar',
            title: 'Принцесса спорта гимнастический клуб – Контакты'
        },
        params: true,
        component: ContactView
    },
    {
        path: '/news',
        name: 'Новости',
        meta: {
            menu: 'footer',
            title: 'Новости компании'
        },
        params: true,
        component: NewsView
    },
    {
        path: '//:_(.*)',
        name: 'Страница не найдена',
        meta: {
            title: 'Страница не найдена'
        },
        component: NotFoundView
    },
    {
        path: '/:_(.*)',
        name: 'Страница не найдена',
        meta: {
            title: 'Страница не найдена'
        },
        component: NotFoundView
    },
]


const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to) {
        // console.log(to.path);
        if (to.hash && to.path !== '/') {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        } else {
            return {top: 0}
        }
    },
    routes
})

export default router
