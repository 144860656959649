<script setup>

import router from "@/router";
import {MENU_LINK, PHONE} from "@/services/constants";
import {ref} from "vue";
import {routesNavbar} from "@/services/set-title";

const routes = routesNavbar()


const open = ref(false)


</script>

<template>

  <div>
    <div class="navbar  fixed bg-base-100 xl:h-20 shadow-xl top-0 z-50">


      <div class="hidden lg:block navbar-start">


        <router-link to="/">
          <img alt="logo" class="h-12 lg:ml-6" src="@/assets/img/logo_ps.svg">
        </router-link>


      </div>


      <div class="hidden navbar-center lg:flex">

        <div class="flex p-0 text-gray-700 space-x-3">

          <router-link v-for="route in routes" :key="route.path" :to="route.path">

            <div :class="{ 'border-b-2 border-b-[#00b0dc]': route.path === router.currentRoute.value.path }"
                 class="p-3 cursor-pointer rounded-lg hover:bg-gray-300">

              {{ route.name }}

            </div>

          </router-link>


        </div>


      </div>


      <div class="hidden lg:block navbar-end xl:mr-5 text-right">
        <a :href="'tel:'+ PHONE.number" class="btn btn-ghost">
          <img alt="phone" class="h-1/2 mr-1" src="@/assets/img/phone.svg">
          {{ PHONE.text }}
        </a>
      </div>


      <!--mobile menu-->


      <div class="lg:hidden navbar-start">


        <router-link to="/">
          <img alt="logo" class="h-12 lg:ml-6" src="@/assets/img/logo_ps.svg">
        </router-link>


      </div>


      <div class="lg:hidden navbar-end xl:mr-5">


        <a :href="'tel:'+ PHONE.number" class="btn btn-ghost">
          <img alt="phone" class="h-1/2 mr-1" src="@/assets/img/phone.svg">
        </a>

        <!--      btn-outline btn-circle-->
        <div class="dropdown dropdown-end">
          <label class="btn btn-ghost lg:hidden " tabindex="0" @click="open = true">
            <svg class="lucide lucide-menu" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <line x1="4" x2="20" y1="12" y2="12"/>
              <line x1="4" x2="20" y1="6" y2="6"/>
              <line x1="4" x2="20" y1="18" y2="18"/>
            </svg>
          </label>

          <transition mode="out-in" name="fade">
            <ul v-show="open" id="menu-mobile"
                class="menu menu-lg dropdown-content mt-3 p-2 shadow-xl bg-base-100 rounded-box w-64"
                tabindex="0">

              <li>
                <h2 class="menu-title">Страницы</h2>
                <ul>
                  <li v-for="route in routes" :key="route.path"
                      :class="{ 'font-semibold border-l-2 border-l-[#00b0dc]': route.path === router.currentRoute.value.path }"
                      class="cursor-pointer rounded-lg hover:bg-gray-300">
                    <router-link :key="route.path" :to="route.path"
                                 @click="open = false">
                      {{ route.name }}
                    </router-link>
                  </li>
                </ul>
                <h2 class="menu-title">Ссылки</h2>
                <ul>
                  <li v-for="link in MENU_LINK" :key="link.uri"
                      class="cursor-pointer rounded-lg hover:bg-gray-300">
                    <a :href="link.uri" target="_blank" @click="open = false">
                      {{ link.text }}
                    </a>
                  </li>
                </ul>
              </li>

            </ul>
          </transition>

        </div>

      </div>


    </div>


  </div>


</template>


<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
