<script setup>
import TitleBlock from "@/components/TitleBlock.vue";
import {setTitle} from "@/services/set-title";
import ButtonBlue from "@/components/ButtonBlue.vue";

setTitle()


</script>

<template>

  <div class="lg:p-12 p-6">

    <TitleBlock>
      Страница не найдена
    </TitleBlock>


    <div class="flex justify-center">


      <div class="text-center">

        <div class="">
          <img :src="require('@/assets/img/404.webp')" alt="404">
        </div>

        <div class="">
          <router-link class="" to="/">
            <ButtonBlue>
              На главную
            </ButtonBlue>
          </router-link>
        </div>

      </div>

    </div>

  </div>

</template>


