import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style.css'
import axios from "axios";
import YmapPlugin from 'vue-yandex-maps'


axios.defaults.baseURL = 'https://bx.5500.by/api/site/';


const settings = {
    apiKey: '12c57cd3-20ab-4139-b38a-4332a772af26',
    debug: false,
    version: '2.1'
}


createApp(App)
    .use(store)
    .use(router)
    .use(YmapPlugin, settings)
    .directive('focus', {
        mounted(el) {
            el.focus()
        }
    })
    .mount('#app')
