<script setup>
import {reactive, ref} from "vue";
import InputBlue from "@/components/InputBlue.vue";
import ButtonBlue from "@/components/ButtonBlue.vue";
import axios from "axios";
import utm from "@/services/utm-form";
import {PHONE} from "@/services/constants";

const response = ref({status: false, fields: []})
const sendForm = ref(false)
const showPromocode = ref(false)

const props = defineProps({
      cityId: {
        default: null
      },
      placeId: {
        default: null
      },
      groupId: {
        default: null
      }
    }
)


const form = reactive({
  name: null,
  phone: null,
  promocode: null,

  ...props,
  // cityId: props.cityId,
  // placeId: props.placeId,
  // groupId: props.groupId,
  site: 'rb',
  ...utm
})


async function submit() {
  sendForm.value = true
  response.value = {status: false, fields: []}
  axios.defaults.baseURL = 'https://bx.5500.by/api/app/form/';

  try {
    response.value = (await axios.post('response', form)).data
  } catch (e) {
    console.log(e);
  }
  sendForm.value = false
}


// watch(modalOpen, () => {
//   modalOpen ? document.querySelector("input[name='name']").focus() : null
// })


</script>

<template>


  <div>

    <transition mode="out-in" name="bounce">

      <div v-if="response.status" class="text-xl text-center">
        <div>
          Ваша заявка принята.<br>
          Мы обязательно с вами свяжемся и пригласим на первую БЕСПЛАТНУЮ тренировку.
          Спасибо!
        </div>
        <img :src="require('@/assets/img/b_bg.webp')" alt="thx">
      </div>


      <div v-else>

        <form class="space-y-3" @submit.prevent="submit">

          <InputBlue v-model:input="form.name"
                     :errors="response?.fields"
                     inputmode="text"
                     name="name"
                     placeholder="Ваше имя"
          />

          <InputBlue v-model:input="form.phone"
                     :errors="response?.fields"
                     :mask="PHONE.mask"
                     :placeholder="PHONE.placeholder"
                     inputmode="tel"
                     name="phone"
          />


          <transition mode="out-in" name="bounce">

            <div v-if="!showPromocode" class="cursor-pointer text-center" @click="showPromocode=true">
              Есть промокод?
            </div>

            <div v-else class="flex gap-6">
              <InputBlue v-model:input="form.promocode"
                         :errors="response?.fields"
                         inputmode="text"
                         name="promocode"
                         placeholder="Промокод"
              />

            </div>

          </transition>

          <div class="form-control">
            <label class="cursor-pointer label">
              <input checked="checked" class="checkbox checkbox-info mr-3" required type="checkbox"/>
              <span class="label-text text-white font-semibold">
                Отправляя форму я соглашаетесь на обработку персональных данных.
              </span>
            </label>
          </div>


          <div class="grid pt-4 mt-6">

            <ButtonBlue :disabled="sendForm" type="submit">
              <span :class="{hidden:!sendForm}" class=" loading loading-spinner loading-md mr-2"></span>
              Записаться на занятие
            </ButtonBlue>

          </div>


        </form>
      </div>

    </transition>
  </div>


</template>

<style>
.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}


</style>
