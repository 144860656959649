<script setup>

</script>

<template>
  <div class="text-center mt-8 mb-4 mx-2">
    <div class="text-rose-400 xl:text-3xl text-2xl font-bold title-ps">
      <slot>
      </slot>
    </div>
  </div>
</template>

<style>
.title-ps {
  scroll-margin: 100px;
}
</style>
