<script setup>
import TitleBlock from "@/components/TitleBlock.vue";
import {setTitle} from "@/services/set-title";
import ButtonRoseHref from "@/components/ButtonRoseHref.vue";
import ModalDownCenter from "@/components/ModalDownCenter.vue";
import {openModal} from "@/services/modal-func";

setTitle()
</script>

<template>
  <div class="lg:p-12 p-6">


    <TitleBlock>
      Вакансии
    </TitleBlock>


    <div class="whitespace-normal">
      <p><strong>Здравствуйте!</strong></p>
      <p><img alt="hodozhki 2" src="@/assets/img/hodozhki_2.webp"
              style="margin-left: 10px; margin-bottom: 10px; float: right;"></p>
      <strong>Если вы хотите быть Тренером</strong> клуба "Принцесса спорта"


                                                    Будем рады знакомству!&nbsp;
      <p class="my-3">
        <ButtonRoseHref @click="openModal()">
          Хочу быть тренером
        </ButtonRoseHref>
      </p>


      <p><strong>Если вы хотите стать Партнером</strong>, т.е. открыть клуб "Принцесса спорта" в вашем городе, то дальше
                                                        информация для вас.</p>
      <p><strong>Общая информация:</strong></p>
      <p>Мы создали замечательный&nbsp;гимнастический клуб "Принцесса спорта".</p>
      <p>Наш сайт:&nbsp;<a href="https://princessasporta.by/" title="наш сайт">princessasporta.by</a></p>
      <p>Занятия организованы&nbsp;в г.Минске, г.Бресте, г.Гомеле, г. Витебске, г. Бобруйске, г. Осиповичи, г. Борисове
         и других городах. Всего около 50 филиалов.</p>
      <p>Для реализации этого проекта мы ищем Партнеров.<br>В ближайшее время планируем открыть более 50 филиалов в
         городах РБ с населением 10 000 и более человек.</p>
      <p>&nbsp;</p>
      <p><strong>На данный момент нам интересны следующие города Республики Беларусь:</strong><br><br><strong>Минская
                                                                                                              область:</strong>
        Солигорск, Молодечно, Жодино, Слуцк, Вилейка, Дзержинск, Марьина Горка, Столбцы, Смолевичи, Заславль, Несвиж,
        Фаниполь, Березино, Любань, Старые Дороги, Логойск, Клецк, Воложин, Червень.</p>
      <p><strong>Брестская область:</strong> Брест, Пинск, Кобрин, Берёза, Лунинец, Ивацевичи, Пружаны, Иваново,
                                             Дрогичин, Ганцевичи, Жабинка, Микашевичи, Белоозёрск, Столин, Малорита,
                                             Ляховичи.</p>
      <p><strong>Витебская область:</strong> Орша, Новополоцк, Полоцк, Поставы, Глубокое, Лепель, Новолукомль, Городок,
                                             Барань, Толочин.</p>
      <p><strong>Гомельская область: </strong>Гомель, Мозырь, Жлобин, Светлогорск, Речица, Калинковичи, Рогачёв, Добруш,
                                              Житковичи, Хойники, Петриков.</p>
      <p><strong>Гродненская область:</strong> Гродно,&nbsp;Лида, Слоним, Волковыск, Сморгонь, Новогрудок, Мосты, Щучин,
                                               Ошмяны, Берёзовка, Скидель, Кореличи.</p>
      <p><strong>Могилевская область:</strong> Могилёв, Бобруйск, Горки, Осиповичи, Кричев, Климовичи, Быхов, Шклов,
                                               Костюковичи, Мстиславль, Чаусы.</p>
      <p>&nbsp;</p>
      <p>Если вы не нашли свой город в списке городов, ничего страшного. Напишите мне и мы что-нибудь придумаем.<br>Список
         уже занятых городов можно посмотреть здесь: <a href="/branches" rel="noopener noreferrer" target="_blank">princessasporta.by/branches</a>
      </p>
      <p>&nbsp;</p>
      <p>Мы вас всему обучим и передадим вам наши знания, которые позволят организовать занятия на высоком уровне и с
         минимальными рисками.&nbsp;<br>По статистике количество организаций и предпринимателей, прекращающих свою
         деятельность в первый год после открытия составляет около 80%. И еще 10% закрывается в первые три года. Таким
         образом, вероятность того, что у вас получится организовать что-то самостоятельно - не более 10%.</p>
      <p>С нами у вас будет гораздо больше шансов. Да, это не 100%, но в то же время и не 10%.<br><br></p>
      <p>Если вы попробуете и у вас не получится, то единственное, чем вы рискуете, это потерять время. Но если вы
         попробуете и у вас получится&nbsp;организовать&nbsp;гимнастический клуб "Принцесса спорта" в вашем городе, то
         поверьте мне, вы будете замечательно зарабатывать по белорусским меркам. Кроме того, вы получите такие знания,
         которые мы копили в течение 10 лет развития клуба. Думаем, это сильно расширит вашу картину мира.</p>
      <p>&nbsp;</p>
      <p><strong>Обязанности:</strong></p>
      <ul>
        <li>Поиск мест для занятий художественной гимнастикой</li>
        <li>Заключение договоров аренды</li>
        <li>Проведение набора детей в секцию</li>
        <li>Проведение тренировок&nbsp;по гимнастике с девочками&nbsp;4,5 -12 лет&nbsp;</li>
        <li>Ведение документации</li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>Условия:</strong></p>
      <ul>
        <li>Расчет с вами будет происходить следующим образом: Все деньги за тренировки поступают централизованно на
            расчетный счет нашей организации. После чего из суммы взносов отнимаются отнимается комиссия 15% в фонд
            нашей организации, отнимаются все затраты (аренда, коммунальные услуги, реклама...), все оставшиеся деньги
            будут перечислены вам на расчетный счет, как индивидуальному предпринимателю (это несложно) или как
            физическому лицу по договору.
        </li>
        <li>Это предложение действует только для Тренеров и только на те места занятий, где вы сами проводите
            тренировки.
        </li>
        <li>Как правило, у одного Партнера может быть до трех мест занятий и до пяти тренеров/хореографов-помощников.
            Потенциально у вас может заниматься до 600 человек.
        </li>
        <li>Возможный заработок - от 500 до 5000 белорусских рублей &nbsp;в месяц&nbsp;(возможно,&nbsp;будет и больше
            получаться. Все зависит от вас).
        </li>
        <li>Оборудование (ковры, обручи, мячи, скакалки, ленты гимнастические) мы вам предоставим на взаимовыгодных
            условиях. Не думайте пока об этом, т.к. это - не большая проблема&nbsp;))).
        </li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>Требования (обязательно):</strong></p>
      <ul>
        <li>Позитивное настроение и желание менять мир к лучшему.&nbsp;</li>
        <li>Умение и желание постоянно учиться и осваивать что-то новое.</li>
        <li>Среднее специальное или высшее физкультурное или педагогическое образование.</li>
        <li>Порядочность и честность.</li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>Требования (желательно но не обязательно):</strong></p>
      <ul>
        <li>Личные качества: Организованность, креативность, ответственность, коммуникабельность.</li>
        <li>Наличие опыта в тренерской деятельности.</li>
        <li>Владение компьютером на среднем уровне.</li>
        <li>Наличие аккаунтов ВК, Скайп, Gmail.</li>
        <li>Спортивный разряд по художественной гимнастике, спортивной гимнастике,&nbsp;спортивной аэробике или танцам
            (желательно, не ниже КМС).
        </li>
        <li>Возраст от 18 до 60 лет.</li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>Как открыть клуб "Принцесса спорта" в вашем городе:&nbsp;</strong></p>
      <p><br><strong>ШАГ 1:</strong>&nbsp;Заполните, пожалуйста, этот опросник:
      </p>
      <p class="my-3">
        <ButtonRoseHref
            href-my="https://docs.google.com/forms/d/e/1FAIpQLSe82p_1bjGCcVvqrP1awRFi42T2a-2Mz84bINuIVTuEBsRZNg/viewform"
            target="_blank">
          Опросник
        </ButtonRoseHref>
      </p>


      <p><strong>ШАГ 2:</strong> Добавьте&nbsp;руководителя клуба и руководителя этого проекта в друзья во Вконтакте и
                                 напишите такое сообщение "Здравствуйте! Я из города НазваниеГорода. Хочу открыть клуб
                                 "Принцесса спорта". Форму заполнила".:&nbsp;</p>
      <p>Шаройко Александр - руководитель клуба:&nbsp;<a href="https://vk.com/alex_sharoiko" rel="noopener noreferrer"
                                                         target="_blank" title="руководитель клуба">www.vk.com/alex_sharoiko<br></a>Луковец
         Яна - руководитель проекта: <a href="https://vk.com/id19196143" rel="noopener noreferrer" target="_blank"
                                        title="руководитель проекта">www.vk.com/yana_lukovets</a></p>
      <p><strong>ШАГ 3:</strong>&nbsp;Мы вам позвоним и поговорим.&nbsp;</p>
      <p><strong>ШАГ 4:</strong>&nbsp;Если&nbsp;по результату беседы и&nbsp;вы и мы захотим его начать сотрудничать, то
                                начнем. Мы вас всему научим )).</p>
      <p><strong>ШАГ 5:</strong> Пройти бесплатное обучение (удаленно) и открыть&nbsp;гимнастический клуб "Принцесса
                                 спорта" в вашем городе.</p>
      <p><br>Пишите смелее! Будем рады знакомству!<br>С нами у вас получится!</p>
      <p>&nbsp;</p>
      <p>С уважением, руководитель клуба "Принцесса спорта",<br>Шаройко Александр.</p></div>


    <ModalDownCenter
        title="Заявка">

      <component :is="'script'" data-b24-form="inline/124/a1c5ky" data-skip-moving="true">
        (function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var
        h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.by/b2635853/crm/form/loader_124.js')
      </component>

    </ModalDownCenter>


  </div>
</template>

<style scoped>

</style>
