import axios from "axios";

export class DistrictsClass {

    dataApi
    cityId
    options


    async setCityId(cityId) {
        this.cityId = cityId
        this.dataApi = await this.getApi()
        this.options = this.getOptions()
        return this
    }

    getOptions() {
        let dataAllDistrict = []
        this.dataApi.map(function (row) {
            dataAllDistrict.push({
                id: row?.district?.id ?? 0,
                name: row?.district?.name ?? 'Без района'
            })
        })

        return Array.from(
            new Set(dataAllDistrict.map(_ => JSON.stringify(_))),
            _ => JSON.parse(_),
        )
    }

    async getApi() {
        return Object.values((await axios('branches/places/' + this.cityId)).data)
    }

}
