<script setup>
import FirstHome from "@/blocks/Home/FirstHome.vue";
import PlusUs from "@/blocks/Home/PlusUs.vue";
import WhatUs from "@/blocks/Home/WhatUs.vue";
import PlusYou from "@/blocks/Home/PlusYou.vue";
import PhotoVideo from "@/blocks/Home/PhotoVideo.vue";
import BranchesMap from "@/blocks/Home/BranchesMap.vue";
import PriceLink from "@/blocks/Home/PriceLink.vue";
import CommentReviews from "@/blocks/Home/CommentReviews.vue";
import {setTitle} from "@/services/set-title";
import ModalDownCenter from "@/components/ModalDownCenter.vue";
import FormOrder from "@/blocks/FormOrder.vue";


setTitle()


</script>

<template>

  <div>
    <FirstHome/>
    <PlusUs/>
    <WhatUs/>
    <PlusYou/>
    <PhotoVideo/>
    <BranchesMap/>
    <PriceLink/>
    <CommentReviews/>


    <ModalDownCenter
        title="Запишитесь на бесплатное<br>пробное занятие">
      <FormOrder/>
    </ModalDownCenter>

  </div>

</template>


<style>


</style>
