import axios from "axios";

export class NewsClass {

    news

    constructor() {
        this.news = this.getApi()
    }

    async getApi() {
        return (await axios('news')).data;
    }


}
