<script setup>
import TitleBlock from "@/components/TitleBlock.vue";
import {setTitle} from "@/services/set-title";
import {ADDRESS, EMAIL, PHONE} from "@/services/constants";
import axios from "axios";

setTitle()

function delCache() {
  let res = axios.get('cache/del')
  console.log(res)
}
</script>

<template>

  <div class="lg:p-12 p-6">

    <TitleBlock>
      Контакты
    </TitleBlock>


    <div class="flex justify-center">


      <div class="space-y-12">


        <div class="text-xl">
          КОНТАКТНЫЕ <a @click.ctrl="delCache">ДАННЫЕ</a>
        </div>


        <div class="overflow-x-auto">
          <table class="table">

            <tbody class="text-sm">

            <tr>
              <td>Телефон</td>
              <td>
                <a :href="'tel:'+ PHONE.number" class=" font-semibold ">
                  {{ PHONE.text }}
                </a>
              </td>
            </tr>

            <tr>
              <td>Email</td>
              <td>
                <a :href="'mailto:' + EMAIL" class=" font-semibold ">
                  {{ EMAIL }}
                </a>
              </td>
            </tr>

            <tr>
              <td>Адрес</td>
              <td>
                <div class=" font-semibold ">
                  {{ ADDRESS }}
                </div>
              </td>
            </tr>


            </tbody>
          </table>
        </div>


        <div class="text-xl pt-12">
          ОСТАВЬТЕ ОБРАЩЕНИЕ В КОМПАНИЮ
        </div>

        <div class="">
          <div class="space-y-4 shadow-xl lg:m-4 rounded-2xl lg:p-8 bg-[#fb7185]">

            <component :is="'script'" class="" data-b24-form="inline/122/yq3p4j" data-skip-moving="true">
              type="application/javascript">(function (w, d, u) {
              var s = d.createElement('script');
              s.async = true;
              s.src = u + '?' + (Date.now() / 180000 | 0);
              var h = d.getElementsByTagName('script')[0];
              h.parentNode.insertBefore(s, h);
              })(window, document, 'https://cdn-ru.bitrix24.by/b2635853/crm/form/loader_122.js');
            </component>

          </div>
        </div>


      </div>

    </div>

  </div>

</template>
