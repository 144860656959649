export class PlacesClass {
    District
    options
    districtId

    setDistrictClass(District) {
        this.District = District
        this.options = this.getAllPlaceInDistrict()
        return this
    }

    setDistrictId(districtId) {
        this.districtId = districtId
    }

    getAllPlaceInDistrict(districtId = null) {
        let firstDistrictId = this.districtId ?? districtId ?? this.District.options[0]?.id;
        return this.getAllPlaceInCity().filter(function (place) {
            return Number(place?.district?.id) === Number(firstDistrictId);
        })

    }

    getAllPlaceInCity() {
        let data = [];
        let i = 0;
        while (i < this.District.dataApi.length) {
            data = Object.values(data.concat(this.District.dataApi[i]))
            i++;
        }
        return data;
    }

    getDataPlace(placeId, options = null) {
        return options.find(function (place) {
            return Number(place?.id) === Number(placeId);
        })
    }
}
