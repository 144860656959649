<script setup>
import TitleBlock from "@/components/TitleBlock.vue";
import {setTitle} from "@/services/set-title";

setTitle()
</script>

<template>

  <div class="lg:p-12 p-6">

    <TitleBlock>
      Частые вопросы
    </TitleBlock>


    <div class="join join-vertical w-full bg-base-100">

      <div class="collapse collapse-arrow join-item border border-base-300">
        <input name="my-accordion-4" type="radio"/>
        <div class="collapse-title text-xl font-medium text-[#00b0dc]">
          Сколько раз в неделю проходят занятия и какова продолжительность занятий?
        </div>
        <div class="collapse-content"> Занятия проходят 2 раза в неделю, преимущественно в будние дни, либо в
                                       будний день + суббота, как правило, в вечернее время. Оптимальная
                                       длительность тренировки в первый год обучения составляет 60 минут. Это
                                       позволяет ребенку эффективно осваивать новые элементы, а также "плавно"
                                       подготовить организм к физическим нагрузкам. В дальнейшем, на втором
                                       или третьем году обучения с учетом возраста и уровня подготовленности
                                       ребенка формируются "команды". В командах у детей увеличивается общая
                                       продолжительность тренировки, а также их количество, что качественным
                                       образом отражается на уровне подготовленности ребенка. Кроме того, в
                                       командах дополнительно вводится курс хореографии, который позволит
                                       обрести чувство такта, улучшить координацию и подчеркнуть образ юной
                                       гимнастки при участии в соревнованиях и фестивалях.<br>
        </div>
      </div>
      <div class="collapse collapse-arrow join-item border border-base-300">
        <input name="my-accordion-4" type="radio"/>
        <div class="collapse-title text-xl font-medium text-[#00b0dc]">
          Какая стоимость?
        </div>
        <div class="collapse-content"> Сумма ежемесячного взноса зависит от города, в котором проходят занятия
                                       и от количества часов занятий в неделю.<br>Подробно о тарифах можно
                                       узнать, перейдя по <a href="/tseny-i-oplata" title="Цены и оплата">ЭТОЙ
                                                                                                          ССЫЛКЕ</a>.&nbsp;&nbsp;Для
                                       каждого нового члена клуба существует приятный бонус - <strong>бесплатная
                                                                                                      первая
                                                                                                      тренировка</strong>,
                                       на которой вы сможете понаблюдать за её&nbsp;ходом, а ваш ребенок -
                                       попробовать свои силы. Более подробную информацию о бесплатной пробной
                                       тренировке Вы можете уточнить у администратора
          <br>
                                       <!--          <a href="tel:+375293102049">+375 29 310 20 49</a>-->
                                       <!--          <br>-->
                                       <!--          <a href="tel:+375295707940">+375 29 570 79 40</a>-->
                                       <!--          <p></p>-->
          <p>Для вашего удобства мы подготовили ряд способов оплаты занятий:<br>- оплатив квитанцию в любом
             банке&nbsp;<br>- через клиент-банк&nbsp;<br>- воспользовавшись услугами электронных платежных сервисов

            <a href="https://e-pay.by/" rel="nofollow">www.e-pay.by</a> и
            <a href="https://ipay.by/" rel="nofollow">www.ipay.by</a>&nbsp;
             Более подробная информация об оплате будет указана
             <!--            <a href="/cardpay" title="Как оплатить карточкой">ЗДЕСЬ</a>.-->
          </p>

        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">Как оплатить карточкой?
          </div>
          <div class="collapse-content">&nbsp;<strong>СПОСОБ 1</strong>
            <span>&nbsp;(если вы используете Интернет-Банкинг)</span>
            <p></p>
                                        1. Зайдите в Личный кабинет своей пластиковой карточки (на компьютере или через
                                        приложение в мобильном телефоне)<br>2. Найдите нашу организацию:<br>&nbsp;
                                        &nbsp;
                                        &nbsp;Благотворительность, общественные объединения<br>&nbsp; &nbsp; &nbsp;
                                        &nbsp;&nbsp;Спортивные объединения<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        БФОГ-Принцесса спорта-Сильфида<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp; &nbsp;<span
            >Членский взнос</span><br>&nbsp;<img alt="" height="709"
                                                 src="@/assets/img/faq/bfog_ERIP.gif"
                                                 width="482">

                                        3. Введите номер Членского билета<br>4. Введите сумму для оплаты<br>5.
                                        Оплатите<br>6. Распечатайте квитанцию и передайте тренеру (или перешлите на
                                        и-мейл, Viber или через соц сеть Вконтакте).
            <br><br>
            <strong>СПОСОБ 2&nbsp;</strong><br><a href="https://e-pay.by/" rel="nofollow">www.e-pay.by&nbsp;<br></a>Платежный
                                        ресурс
                                        e-pay.by
                                        -
                                        это
                                        совместный
                                        проект
                                        ООО
                                        «ВЕБ ПЭЙ»
                                        и
                                        «Приорбанк»
                                        ОАО.<br>ООО
                                        «ВЕБ ПЭЙ»
                                        является
                                        белорусским
                                        разработчиком
                                        системы
                                        WEBPAY™,
                                        обслуживающей
                                        платежи с
                                        помощью
                                        банковских
                                        платежных
                                        карточек
                                        в
                                        сети
                                        Интернет.<br>Оплата
                                        без
                                        комиссии.

                                        Если вы уже зарегистрированы в системе e-pay.by, то
                                        перейдите&nbsp;по&nbsp;<strong><a
                href="https://e-pay.by/servicetree?serviceex=1,0,329511" rel="noopener noreferrer" target="_blank"
                title="Ссылка на сервис e-pay">этой ссылке</a></strong>.<br>Если еще не зарегистрированы, то:
                                        1. Перейдите по ссылке:&nbsp;<a href="https://e-pay.by/" rel="nofollow">www.e-pay.by</a><br><span>2. Найдите нашу&nbsp;</span><span
            >организацию</span><span>:</span><br
            ><span
            >&nbsp; &nbsp; &nbsp;Учреждения образования</span><br
            ><span>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Спорт&nbsp;и физическое развитие</span><br><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; БФОГ РОО - Членский взнос</span>

            <br><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><img
                alt="" src="@/assets/img/faq/e-pay_9_2017.jpg" width="713">
                                        3. Введите номер банковской карты<br>4. Введите номер членского билета<br>5.
                                        Введите сумму для оплаты<br>6. Оплатите<br>7. Распечатайте квитанцию и
                                        передайте
                                        тренеру (или перешлите на и-мейл, Viber или через соц сеть Вконтакте).
                                        &nbsp;
            <strong><br><br>СПОСОБ 3 (через сервис&nbsp;<a href="https://ipay.by/"
                                                           rel="nofollow">www.ipay.by</a>)</strong><br>
                                        «Мобильные платежи&nbsp;<strong>iPay</strong>» – современная платежная система,
                                        которая позволяет вам, используя средства со своего лицевого счета мобильного
                                        телефона
                                        (<strong>Белорусских операторов МТС, velcom и life:</strong>

                                        ), совершать покупки в интернете, приобретать билеты в театр и на концерт,
                                        погашать кредит, оплачивать коммунальные услуги, доступ в интернет, ТВ,
                                        принимать участие в интернет-аукционах, играть в различные лотереи, жертвовать
                                        на благотворительные цели, а также оплачивать более 36 000 других услуг во всех
                                        регионах страны.<br>
                                        1.Выберите оператора сотовой связи<br>
                                        2. Найдите нашу&nbsp;организацию:<br>Общественные объединения<br><span>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Спортивные><span>&nbsp;</span><span>БФОГ-Принцесса спорта-Сильфида</span><br><span></span><span>Членский взнос</span></span>
            <img alt="" src="@/assets/img/faq/bfog_ERIP_22018.gif">
            <br>3. Пройдите процедуру подтверждения номера телефона<br>4. Введите номер членского билета<br>
            <span>5.Введите сумму для оплаты</span><br><span>6. Оплатите</span><br><span>7. Распечатайте квитанцию и передайте тренеру (или перешлите на и-мейл, Viber или через соц сеть Вконтакт</span>


          </div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">Что нужно взять&nbsp;на
                                                                         первую тренировку?
          </div>
          <div class="collapse-content"> Первый раз на занятие можно привести ребенка в любой спортивной
                                         форме:<br> Вариант 1: шортики, маечка, носочки<br> Вариант 2:
                                         спортивный костюм.<br> Вариант 3 (идеальный): спортивный купальник и
                                         колготы. <br> Обувь на занятии не нужна, т.к. занятия проходят на
                                         ковре.<br> Больше для первой тренировки ничего не нужно. Приходите и
                                         пробуйте! А наши замечательные тренеры постараются провести первое
                                         занятие так, чтобы вашей маленькой принцессе понравилось!<br></div>
        </div>

        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">Какая форма нужна для
                                                                         занятий?
          </div>
          <div class="collapse-content"> Для занятий в нашем клубе необходима специальная спортивная форма,
                                         состоящая из гимнастического купальника (желательно, черный, без
                                         юбочки), колгот и сменной обуви. Однако, для посещения первого занятия
                                         достаточно повседневной спортивной формы состоящей из шортиков, маечки,
                                         носочков, либо спортивного костюма со сменной обувью. В случае
                                         затруднений с покупкой гимнастического купальника вы можете обратиться
                                         к своему тренеру, который с удовольствием проконсультирует вас и
                                         поможет подобрать необходимый купальник для вашей принцессы.<br> <img
                alt="Форма для занятий" height="317" src="@/assets/img/faq/photosforms.jpg" width="440"><br></div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">А медицинская справка
                                                                         нужна?
          </div>
          <div class="collapse-content"> На первую ознакомительную тренировку можно приходить без справки.
                                         Начиная со второй тренировки - справка о том, что ребенку можно
                                         заниматься гимнастикой – обязательна. Взять данную справку можно в
                                         поликлинике по месту жительства, обратившись к своему врачу-педиатру.
                                         Приводить ребенка в поликлинику не обязательно.<br> <img
                alt="Справка" height="317" src="@/assets/img/faq/reference.jpg" width="440"><br></div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">А мой ребенок будет
                                                                         участвовать в
                                                                         соревнованиях?
          </div>
          <div class="collapse-content"> Члены клуба принимают участие в ряде соревнований, организованных
                                         непосредственно нашим клубом, а также нашими партнерами и друзьями.
                                         Среди соревнований – турнир "Принцесса спорта", проходящий 2 раза в
                                         год. Ежегодно проводится масштабный Республиканский турнир, куда
                                         съезжаются маленькие принцессы со всех уголков Беларуси. Наша
                                         филиальная сеть растет, открываются новые места для занятий в различных
                                         городах Беларуси. Дети выезжают на соревнования в другие города
                                         Беларуси, где они не только демонстрируют чему научились за отчетный
                                         период, но и посещают экскурсии и выставки в сопровождении тренеров,
                                         узнают о культуре города. В летний период для всех желающих
                                         организованны спортивно-оздоровительные сборы в лагерях Беларуси,
                                         спортивно-оздоровительные поездки в Болгарию. Проделана определенная
                                         работа по сотрудничеству с нашими друзьями и коллегами из
                                         Финляндии.<br></div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">У вас профессиональная
                                                                         школа
                                                                         художественной
                                                                         гимнастики или
                                                                         любительская?
          </div>
          <div class="collapse-content"> Для начала, несколько слов о нашей школе. Клуб художественной
                                         гимнастики "Принцесса спорта" обладает порядком 40-ка филиалов по
                                         Республике Беларусь, свыше 30 филиалов расположено в городе Минске. В
                                         наш клуб мы принимаем практически всех детей, соответствующих
                                         возрастным рамкам и не имеющих медицинских ограничений к занятиям
                                         художественной гимнастикой. В нашем клубе дети занимаются
                                         художественной гимнастикой с элементами гимнастики оздоровительной. Что
                                         это значит? Это значит, что дети изучают все те же элементы
                                         художественной гимнастики, что и в спортивной школе, но по упрощенной
                                         программе, с акцентом на оздоровительный эффект физических упражнений.
                                         Отдельный упор в тренировках идет на укрепление мышц спины и
                                         формирование здоровой осанки. Многие наши тренеры являются мастерами
                                         спорта, а некоторые даже мастера спорта международного класса. Мы
                                         сотрудничаем с государственными спортивными школами по программе
                                         выявления талантов. В случае, если Ваш ребенок показывает определенные
                                         результаты, а также обладает необходимыми спортивными данными, тренер
                                         будет рекомендовать родителям его перевод в государственную спортивную
                                         школу.<br></div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">У вас есть система скидок?
          </div>
          <div class="collapse-content">В нашем клубе действует определенная система скидок:<br> -<strong>бесплатная
                                                                                                          первая
                                                                                                          тренировка</strong>
                                        для новых членов клуба;<br> <strong>- 10%&nbsp;</strong>скидка многодетным
                                        семьям
                                        (при предоставлении оригинала и копии удостоверения);<br><strong>- 10%</strong>
                                        скидка, если занятия посещают 2 и более детей из одной семьи.
            <p class="MsoNormal">
              <a href="http://5500.by/statement" rel="nofollow" target="_blank" title="Положение о взносах">
                Положение о взносах
              </a>
            </p>

          </div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">Если ребенок пропускает
                                                                         занятия по болезни, то
                                                                         уменьшается ли
                                                                         сумма ежемесячного взноса?
          </div>
          <div class="collapse-content"> В&nbsp;случае болезни ребенка вам необходимо передать медицинскую
                                         справку своему тренеру, который произведет частичный перерасчет взноса
                                         за следующий месяц.<p></p>
            <p class="MsoNormal">При пропусках занятий по болезни в группах начальной подготовки производится перерасчет
                                 взноса за следующий после выздоровления месяц, согласно Положения 3 к Положению о видах
                                 и
                                 размерах взносов членов РОО "БФОГ", порядке и сроках их уплаты.&nbsp;&nbsp;</p>
            <p class="MsoNormal"><a href="https://bfog.atservers.net/owncloud/index.php/s/eg1Gdys0n0L3Lp5"
                                    rel="nofollow"
                                    title="Регион 1. Расчет суммы взносов.">Регион 1. Расчет суммы взносов.</a></p>
            <p class="MsoNormal"><a href="https://bfog.atservers.net/owncloud/index.php/s/n8oPfCWu40ChfoO"
                                    rel="noopener noreferrer"
                                    target="_blank" title="Регион 2. Расчет суммы взносов.">Регион 2. Расчет суммы
                                                                                            взносов.</a></p>
            <p class="MsoNormal"><a href="https://bfog.atservers.net/owncloud/index.php/s/cZV3beGAAJ7t4JX"
                                    rel="nofollow"
                                    title="Регион 3. Расчет суммы взносов при пропусках при болезни.">Регион 3. Расчет
                                                                                                      суммы
                                                                                                      взносов.</a></p>
            <p class="MsoNormal"><a href="https://bfog.atservers.net/owncloud/index.php/s/bhb36pgmRAwZtKr"
                                    rel="nofollow"
                                    title="Регион 4. Расчет суммы взносов.">Регион 4. Расчет суммы взносов.</a></p>
            <p class="MsoNormal"><a href="https://bfog.atservers.net/owncloud/index.php/s/G9AattPeZiGCIF6"
                                    rel="nofollow"
                                    title="Регион 5. Расчет суммы взносов.">Регион 5. Расчет суммы взносов.</a></p>
            <p><a href="https://bfog.atservers.net/owncloud/index.php/s/jOqiT138P2mwrrh" rel="nofollow"
                  title="Регион 6. Расчет суммы взносов.">Регион 6. Расчет суммы взносов.</a></p>
            <p><a href="https://bfog.atservers.net/owncloud/index.php/s/nqilklvKoiSRFj0" rel="nofollow"
                  title="Регион 7. Расчет суммы взносов.">Регион 7. Расчет суммы взносов.</a></p>
            <p><a href="https://bfog.atservers.net/owncloud/index.php/s/VpOukSOiwk52qMQ" rel="nofollow"
                  title="Регион 8. Расчет суммы взносов.">Регион 8. Расчет суммы взносов.</a></p>
          </div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input name="my-accordion-4" type="radio"/>
          <div class="collapse-title text-xl font-medium text-[#00b0dc]">А кто эта девушка в
                                                                         шпагате
                                                                         на главной странице?
          </div>
          <div class="collapse-content"> Это - наш замечательный тренер Наталья Жуковец, мастер спорта по
                                         художественной гимнастике, участница Чемпионата Мира по эстетической
                                         гимнастике.<br></div>
        </div>

        <!--        <div class="collapse collapse-arrow join-item border border-base-300">-->
        <!--          <input name="my-accordion-4" type="radio"/>-->
        <!--          <div class="collapse-title text-xl font-medium text-[#00b0dc]">Почему главная страница-->
        <!--                                                                         сайта называется-->
        <!--                                                                         www.5500.by?-->
        <!--          </div>-->
        <!--          <div class="collapse-content"> Вы слышали про «Правило 10 000 часов» Малькольма Гладуэла? Суть в-->
        <!--                                         следующем: «Чтобы стать профессионалом в любом деле, нужно в этой-->
        <!--                                         области провести 10 000 часов». Наш сайт называется 5500 потому, что-->
        <!--                                         нам бы хотелось, чтобы дети до 16 лет провели в нашем клубе именно это-->
        <!--                                         количество часов. Учитываются тренировки, соревнования и спортивные-->
        <!--                                         сборы. По нашим расчетам, профессиональные спортсмены к 15 годам-->
        <!--                                         проводят в зале около 15 000 часов. Да, в нашем клубе ваш ребенок не-->
        <!--                                         станет профессиональным спортсменом, но тем не менее у него будет-->
        <!--                                         замечательная возможность взять от спорта все самое лучшее: здоровье,-->
        <!--                                         радость от занятий, поездки по стране и за ее пределы, спортивные-->
        <!--                                         сборы, дисциплину, умение планировать свой день, ответственность,-->
        <!--                                         трудолюбие. По нашему глубокому убеждению, занятия спортом, прежде-->
        <!--                                         всего, должны укреплять здоровье, формировать характер, доставлять-->
        <!--                                         радость. Наша программа направлена именно на это. Мы даже вынесли эту-->
        <!--                                         идею в наш девиз: "ЗДОРОВЬЕ. ХАРАКТЕР. КРАСОТА.", т.е. мы хотим, чтобы-->
        <!--                                         ваши дети были здоровыми, красивыми, и чтобы у них был спортивный-->
        <!--                                         характер. Уважаемые родители, ведь именно этого хотите и вы! Занятия в-->
        <!--                                         нашем клубе – отличный помощник в достижении этой цели! Кроме того, у-->
        <!--                                         вашего ребенка останется еще 9500 часов на то, чтобы параллельно-->
        <!--                                         добиться успеха и в других областях. Наша цель - 5500! ))-->
        <!--          </div>-->
        <!--        </div>-->

      </div>

    </div>
  </div>
</template>
