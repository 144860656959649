<script setup>

import TitleBlock from "@/components/TitleBlock.vue";
import {openModal} from "@/services/modal-func";
import ButtonBlue from "@/components/ButtonBlue.vue";
import {onMounted, ref} from "vue";
import {CommentsClass} from "@/api/CommentsClass";

const slide = ref(0)
const comments = ref()


onMounted(async () => {
  comments.value = await (new CommentsClass()).options
})

function increment() {
  if (slide.value >= comments.value.length - 1) {
    slide.value = 0;
  }
  slide.value++
}


function discernment() {
  if (slide.value <= 0) {
    slide.value = comments.value.length;
  }
  slide.value--
}


</script>

<template>

  <div class="text-center my-20 grid justify-items-center">

    <TitleBlock class="mb-12">
      ОТЗЫВЫ
    </TitleBlock>


    <div class="flex place-items-center lg:gap-8">

      <div class="cursor-pointer absolute left-0 lg:relative" @click="discernment">
        <svg class="w-12 h-12 text-rose-500" fill="none" stroke="currentColor" stroke-width="1.5"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.75 19.5L8.25 12l7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>

      <div v-if="comments"
           class="lg:max-w-[700px] max-w-[350px] overflow-hidden m-auto flex bg-white shadow-lg rounded-xl">


        <div :style="{'margin-left': '-' + (100 * slide) +'%'}"
             class="flex transition-all ease-linear delay-50 m-12 justify-between">


          <div v-for="(comment, index) in comments" :key="index"
               class="p-6 lg:min-w-[700px] min-w-[350px] grid content-between">


            <div class="text-gray-600 w-full m-auto text-center text-lg md:text-3xl">


              <div class="rating rating-md mb-10">
                <input class="mask mask-star-2 bg-orange-400" name="rating-7" type="radio"/>
                <input class="mask mask-star-2 bg-orange-400" name="rating-7" type="radio"/>
                <input class="mask mask-star-2 bg-orange-400" name="rating-7" type="radio"/>
                <input class="mask mask-star-2 bg-orange-400" name="rating-7" type="radio"/>
                <input checked class="mask mask-star-2 bg-orange-400" name="rating-7" type="radio"/>
              </div>


              <div class="flex lg:h-[100px] hover:h-full  h-fit justify-between cursor-pointer
             hover:transition-all ease-in-out delay-75">

                <div class="font-bold text-[#00b0dc] mr-3 hidden lg:block">
                  “
                </div>

                <div class="line-clamp-3 hover:line-clamp-none hover:transition-all ease-in-out delay-75 text-xl">
                  {{ comment.comment }}
                </div>

                <div class="font-bold text-[#00b0dc] ml-3 hidden lg:block">
                  ”
                </div>

              </div>

            </div>


            <div class="flex items-center justify-center mt-8">

              <div class="flex items-center justify-center ml-2">
            <span class="mr-2 text-lg font-semibold text-[#00b0dc] capitalize truncate w-24">
                  {{ comment.name }}
            </span>
                <span class="text-xl font-light text-gray-400">
                /
            </span>
                <span class="ml-2 text-gray-400 text-md">
                    {{ comment.date }}
            </span>

              </div>

            </div>

          </div>

        </div>


      </div>

      <div class="cursor-pointer  absolute right-0 lg:relative" @click="increment">
        <svg class="w-12 h-12 text-rose-500" fill="none" stroke="currentColor" stroke-width="1.5"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.25 4.5l7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>

    </div>


    <div class="w-fit mt-12">
      <ButtonBlue @click="openModal('modal-order')">
        <span class="hidden loading loading-spinner loading-md mr-2"></span>
        Записаться на занятие
      </ButtonBlue>
    </div>


  </div>


</template>

<style scoped>


</style>
