import axios from "axios";
import {NO_PLACES} from "@/services/constants";

export class GroupsController {
    placeId;
    Places;
    groups;

    async setPlaceId(placeId) {
        this.placeId = placeId;
        this.groups = await this.getApi();
        return this;
    }

    setPlacesClass(Places) {
        this.Places = Places
        return this
    }

    async getApi() {
        return Object.values((await axios('branches/groups/' + this.placeId)).data)
    }

    async findAllGroups(placeId, tag = null) {

        let data = this.Places.getAllPlaceInCity().find(function (place) {
            return Number(place.id) === Number(placeId)
        })?.groups

        let findPriceAndCountInGroup = (groupId) => {
            return this.groups?.find(function (group) {
                return Number(group?.id) === Number(groupId)
            })
        }

        let getCountFreePeople = (data) => {

            let count = Number(data?.max_pupils_count) - Number(data?.pupils_count)
            let color = 'orange'
            let text = count

            if (count >= 5) {
                text = '5+'
                color = 'sky'
            }

            if (count <= 0 || !Number.isInteger(count)) {
                count = 0
                color = 'gray'
                text = NO_PLACES
            }

            return {text: text, number: Number(count) ?? 0, color: color}
        }


        let data2 = data.map(function (group) {
            return Object.assign(
                group,
                {pupils_count: findPriceAndCountInGroup(group.id)?.pupils_count},
                {count: getCountFreePeople(findPriceAndCountInGroup(group.id))}
            )

        })

        if (tag) {
            location.href = tag
        }
        return data2

        // return data2.sort(function (a, b) {
        //     if (a.count.number > b.count.number) {
        //         return -1;
        //     }
        //     if (a.count.number < b.count.number) {
        //         return 1;
        //     }
        //     return 0;
        // });

    }


    findMatchDay(group, day) {
        return group.schedules.find(function (sched) {
            return Number(sched.day_of_week) === (Number(day) + 1)
        })?.start_at
    }
}
