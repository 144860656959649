<script setup>
import NavbarMenu from "@/blocks/NavbarMenu.vue";
import FooterMenu from "@/blocks/FooterMenu.vue";
import {bitrix24} from "@/services/bitrix-widget";

bitrix24()


</script>


<template>

  <nav>
    <NavbarMenu/>
  </nav>

  <main id="page" class="xl:mt-20 mt-12 min-h-screen">
    <router-view v-slot="{ Component }">
      <transition appear mode="out-in" name="fade">
        <component :is="Component"/>
      </transition>
    </router-view>
  </main>

  <footer>
    <FooterMenu/>
  </footer>

</template>

<style>
html {
  scroll-behavior: smooth;
  font-family: "Noto Sans", serif;
  font-size: 18px;
  color: #2D2926;
  background: url('~@/assets/img/all-bg.webp') repeat;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


</style>
