export const PHONE = {
    text: '+375 (29) 570-79-40',
    number: '+375295707940',
    placeholder: '+375 (__) ___-__-__',
    mask: '+375 (00) 000-00-00',
    prepareInt: '+375 '
}
export const EMAIL = 'info@5500.by'

export const ADDRESS = 'РОО “БФОГ” УНП 805001535. Почтовый адрес: 220140 г.Минск, ул. Притыцкого 62/2'

export const DAY_WEEK = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
export const PLUS_US = [
    {
        title: 'ТРЕНИРОВКИ РЯДОМ С ДОМОМ',
        text: 'Гимнастический клуб “Принцесса спорта” работает в 48 филиалах Минска в следующих районах: Фрунзенский, Московский, Ленинский, Заводской, Первомайский, Октябрьский, Советский, Партизанский. Кроме этого, уроки по эстетической гимнастике с предметом проходят в Колодищах и Новой Боровой.',
        img: 'us01.webp'
    },
    {
        title: 'ГРУППЫ ФОРМИРУЮТСЯ С УЧЕТОМ ВОЗРАСТА И УРОВНЯ ПОДГОТОВКИ',
        text: 'Группы формируются с учетом возраста и уровня подготовки девочек: 3-4 года, 4,5-6 лет, 6-8 лет, 8-10 лет и 10-12 лет. Количество гимнасток в команде зависит от их возраста (обычно от 8 до 14 человек).',
        img: 'us02.webp'
    },
    {
        title: 'СИСТЕМА МОТИВАЦИИ',
        text: 'Тренеры гимнастического клуба “Принцесса спорта” поддерживают и уделяют внимание каждому ребенку. В клубе разработана комплексная система мотивации девочек, которая показывает результаты на протяжении многих лет',
        img: 'us03.webp'
    },
    {
        title: 'ПРОФЕССИОНАЛЬНЫЕ ТРЕНЕРЫ',
        text: 'Среди них — мастера спорта международного класса, участники и призеры чемпионатов мира и Олимпийских игр! У всех тренеров гимнастического клуба “Принцесса спорта” большой педагогический опыт.',
        img: 'us04.webp'
    },
    {
        title: 'НАСЫЩЕННАЯ СПОРТИВНАЯ ЖИЗНЬ',
        text: 'Воспитанницы гимнастического клуба “Принцесса спорта” участвуют в международных турнирах и фестивалях. Помимо этого, клуб ежегодно организует республиканские турниры «Принцесса спорта», где все команды демонстрируют свое мастерство.',
        img: 'us05.webp'
    },
    {
        title: 'АБСОЛЮТНАЯ БЕЗОПАСНОСТЬ!',
        text: 'Команда клуба уделяет пристальное внимание вопросу безопасности гимнасток. Разработана клубная система, запрещающая опасные для здоровья упражнения. Все залы клуба оснащены профессиональным напольным покрытием, которое снижают риск получения травмы во время тренировок.',
        img: 'us06.webp'
    }
]

export const PLUS_YOU = [
    'Развивается гибкость и музыкальность',
    'Укрепляются мышцы спины и формируется правильная осанка, и походка',
    'Совершенствуется координация, выносливость и работа нервной системы',
    'Вырабатывается самодисциплина, сила воли и умение управлять своим телом',
    'Появляется умение работать в команде и выстраивать отношения со сверстниками'
]

export const PHOTO_CHILDREN = ['photo (1).webp', 'photo (2).webp', 'photo (3).webp', 'photo (4).webp', 'photo (5).webp',
    'photo (6).webp', 'photo (7).webp', 'photo (8).webp',]

export const URL_YOUTUBE = 'https://www.youtube.com/embed/qUWsC4lKQCA'

export const URL_YOUTUBE_CHANNEL = 'https://www.youtube.com/channel/UCPJMhOLE3OB1BKOenz2zVyQ'


export const MENU_LINK = [
    {text: 'Магазин', uri: 'https://www.clubshop.by/catalog/princessasporta/'},
    {text: 'Личный кабинет', uri: 'https://mysportselements.com/login'},
]

export const URL_SOCIAL = [

    {
        name: 'vk', uri: 'https://vk.com/princessasporta',
        icon: 'fab fa-vk'
    },
    {
        name: 'instagram', uri: 'https://www.instagram.com/princessasporta/',
        icon: 'fab fa-instagram'
    },
    {
        name: 'ok', uri: 'https://ok.ru/group/54008571363514',
        icon: 'fab fa-odnoklassniki'
    },
    {
        name: 'facebook', uri: 'https://www.facebook.com/princessasporta',
        icon: 'fab fa-facebook'
    },
    {
        name: 'youtube', uri: 'https://www.youtube.com/channel/UCPJMhOLE3OB1BKOenz2zVyQ',
        icon: 'fab fa-youtube'
    },
    {
        name: 'tik', uri: 'https://www.tiktok.com/@princessasporta',
        icon: 'fab fa-itunes-note'
    }

]


export const COMPANY_MAP = [
    {
        title: "Наименование ЮЛ",
        data: "Республиканское общественное объединение \"Белорусская федерация общеоздоровительной гимнастики\""
    },
    {
        title: "УНП",
        data: "805001535"
    },
    {
        title: "Юридический адрес",
        data: "Минская обл., Смолевичский р-н, г. Смолевичи, ул. Первомайская, д. 136"
    },
    {
        title: "Наименование банка",
        data: "ОАО \"Технобанк\"6"
    },
    {
        title: "Расчетный счёт",
        data: "3015 1281 8000 2000 0000"
    },
]

export const NO_PLACES = 'мест нет'


