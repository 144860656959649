<script setup>

import {YandexCollection, YandexMap, YandexMarker} from "vue-yandex-maps";

defineProps({
  dataCoord: {
    type: Object,
    required: true
  }
})


const zoom = 16

</script>

<template>
  <div v-if="dataCoord?.city && dataCoord?.place[0]">

    <yandex-map v-if="dataCoord.place.length===1"
                :coordinates="[dataCoord.place[0]?.location_lat, dataCoord.place[0]?.location_long]"
                :zoom="zoom">
      <YandexCollection :options="{ preset: 'islands#blueDotIcon'}">
        <yandex-marker
            v-for="(place, index) in  dataCoord.place"
            :key="index"
            :coordinates="[place?.location_lat, place?.location_long]"
            :marker-id="index"
            @click="$emit('onClickMap', place)"
        />
      </YandexCollection>
    </yandex-map>

    <yandex-map v-else :bounds="dataCoord.city">
      <YandexCollection :options="{ preset: 'islands#blueDotIcon'}">
        <yandex-marker
            v-for="(place, index) in  dataCoord.place"
            :key="index"
            :coordinates="[place?.location_lat, place?.location_long]"
            :marker-id="index"
            @click="$emit('onClickMap', place)"
        />
      </YandexCollection>
    </yandex-map>

  </div>
</template>

<style scoped>

.yandex-container {
  height: 550px !important;
}

.yandex-balloon {
  height: 250px;
  width: 200px;
}

</style>
