const queryString = window.location.search;

const params = new URLSearchParams(queryString);

const utm = []

params.forEach((value, key) => {
    utm[key] = value
})

export default utm
