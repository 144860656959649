<script setup>
import {onBeforeMount, ref, watch} from "vue";
import {setTitle} from "@/services/set-title";
import TitleBlock from "@/components/TitleBlock.vue";
import SelectPrimary from "@/components/SelectPrimary.vue";
import ModalDownCenter from "@/components/ModalDownCenter.vue";
import FormOrder from "@/blocks/FormOrder.vue";
import {DistrictsClass} from "@/api/DistrictsClass";
import {CitiesClass} from "@/api/CitiesClass";
import {PlacesClass} from "@/api/PlacesClass";
import {GroupsController} from "@/api/GroupsController";
import ButtonRoseSm from "@/components/ButtonRoseSm.vue";
import ButtonBlueSm from "@/components/ButtonBlueSm.vue";
import {openModal} from "@/services/modal-func";
import {DAY_WEEK, NO_PLACES} from "@/services/constants";
import NotifyAddress from "@/blocks/Branches/NotifyAddress.vue";
import MapPlaces from "@/blocks/Branches/MapPlaces.vue";


setTitle();

const cityId = ref(7);

const districtId = ref(8);

const placeId = ref(); //101

const groupId = ref();

const loadingForm = ref(false);

const tagScroll = ref();

const optionsCities = ref([{
  id: '', name: ''
}]);

const optionsDistrict = ref([{
  id: '', name: ''
}]);

const optionsPlaces = ref([{
  id: '', name: ''
}]);

const optionsGroups = ref([{
  id: '', name: ''
}]);

const placeNow = ref();
const dataCoord = ref({city: [[0, 0], [0, 0]], place: []});


const Cities = new CitiesClass();
const District = new DistrictsClass();
const Place = new PlacesClass();
const Group = new GroupsController();


let DistrictSetCity = {};
let PlaceSetDistrict = {};
let GroupSetPlace = {};


onBeforeMount(async () => {
  loadingForm.value = true
  optionsCities.value = await Cities.options;
  DistrictSetCity = await District.setCityId(cityId.value)
  optionsDistrict.value = DistrictSetCity.options
  PlaceSetDistrict = Place.setDistrictClass(DistrictSetCity)
  optionsPlaces.value = PlaceSetDistrict.options
  getCoord(District)
  loadingForm.value = false
});


watch(cityId, async () => {
  loadingForm.value = true
  placeNow.value = '';
  optionsGroups.value = []
  DistrictSetCity = await District.setCityId(cityId.value)
  optionsDistrict.value = DistrictSetCity.options
  PlaceSetDistrict = Place.setDistrictClass(DistrictSetCity)
  optionsPlaces.value = PlaceSetDistrict.options
  getCoord(District)
  loadingForm.value = false
});


watch(districtId, async () => {
  loadingForm.value = true
  placeNow.value = '';
  optionsGroups.value = []
  PlaceSetDistrict = Place.setDistrictClass(DistrictSetCity)
  optionsPlaces.value = PlaceSetDistrict.getAllPlaceInDistrict(districtId.value)
  loadingForm.value = false
});


watch(placeId, async () => {
  loadingForm.value = true
  placeNow.value = '';
  GroupSetPlace = await Group.setPlacesClass(PlaceSetDistrict).setPlaceId(placeId.value)
  optionsGroups.value = await GroupSetPlace.findAllGroups(placeId.value)
  placeNow.value = PlaceSetDistrict.getDataPlace(placeId.value, Place.getAllPlaceInCity())
  loadingForm.value = false
  document.querySelector(tagScroll.value).scrollIntoView()
});


function getCoord(District) {
  let allCoordLat = District.dataApi.map((row) => Number(row.location_lat)).filter(element => element != null && element !== 0);
  let allCoordLon = District.dataApi.map((row) => Number(row.location_long)).filter(element => element != null && element !== 0);

  if (allCoordLon.length !== 0 || allCoordLon.length !== 0) {
    dataCoord.value = {
      city: [
        [allCoordLat.reduce((x, y) => Math.min(x, y)), allCoordLon.reduce((x, y) => Math.min(x, y))],
        [allCoordLat.reduce((x, y) => Math.max(x, y)), allCoordLon.reduce((x, y) => Math.max(x, y))]
      ],
      place: District.dataApi
    }
  }

}

function loadGroups(place, tag) {
  // console.log(place)
  districtId.value = place.district.id
  placeId.value = place.id
  tagScroll.value = tag
}

function loadPlacesInDistrict(districtId) {
  if (districtId) {
    return Place.getAllPlaceInDistrict(districtId)
  }
  return null
}


function onClickMap(placeIdMap) {
  let tag = '#pcGroups'
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
      .test(navigator.userAgent)) {
    tag = '#mobileGroups'
  }
  loadGroups(placeIdMap, tag)
}

function openModalSetGroup(group) {
  groupId.value = group.id
  console.log(placeNow);
  openModal()
}


</script>

<template>

  <div>

    <div :class="{ 'blur cursor-progress':loadingForm }" class="lg:p-12 p-6 justify-center">


      <TitleBlock>
        Филиалы и расписание
      </TitleBlock>


      <div class="w-full">

        <div>

          <!--mobile view-->
          <div class="lg:hidden space-y-12 ">


            <div class="flex justify-center">
              <SelectPrimary
                  v-model:change="cityId"
                  :options="optionsCities"
                  :selected="cityId">
                Выберите город
              </SelectPrimary>
            </div>


            <MapPlaces :class="{ 'blur cursor-progress':loadingForm }"
                       :data-coord="dataCoord"
                       class="my-6 rounded-xl shadow-lg"
                       @onClickMap="onClickMap"/>


            <div class="flex justify-center">
              <SelectPrimary
                  v-model:change="districtId"
                  :options="optionsDistrict"
                  :selected="districtId">
                Выберите район
              </SelectPrimary>
            </div>


            <div>
              <div class="mb-6">
                Выберите филиал
              </div>

              <div v-for="place in optionsPlaces"
                   :key="place.id">
                <div class="transition ease-in-out cursor-pointer mb-6 text-sky-600 font-semibold
                          delay-75 hover:-translate-y-1 flex justify-self-start justify-between
                          hover:scale-110 hover:text-[#2e93f9] duration-300"
                     @click="loadGroups(place,'#mobileGroups')">

                  <div>
                    {{ place.name }}
                  </div>
                  <div class="badge badge-ghost text-gray-400 ml-3 truncate">
                    {{ place.type?.name }} {{ place.number }}
                  </div>


                </div>
              </div>
              <div id="mobileGroups"></div>
            </div>

            <div>

              <transition name="bounce">

                <div v-if="placeNow"
                     class="">

                  <div class="mb-6">
                    Выберите группу
                  </div>


                  <NotifyAddress :place-now="placeNow"/>


                  <div class="grid grid-cols-1 gap-6 mt-6">


                    <div v-for="group in optionsGroups" :key="group.id"
                         class="rounded-xl bg-base-100 shadow-lg p-8 grid gap-6 hover">

                      <div class="flex  justify-between place-items-center">

                        <div class="text-left">
                          <div class="font-semibold text-xl">
                            {{ group.ageCategory.name }}
                          </div>
                          <div class="badge badge-ghost text-gray-400 mt-1">
                            {{ group.name }}
                          </div>
                        </div>

                        <div class="text-right text-rose-600">
                          {{ (group.price == 0 || group.price == 1) ? '' : Math.ceil(group.price) + ' руб.' }}
                        </div>

                      </div>
                      <hr>

                      <div class="flex justify-between items-center">


                        <div class="flex">
                          <div v-for="(day, index) in DAY_WEEK"
                               :key="index"
                               class="text-center ">

                            <div v-if="Group.findMatchDay(group, index)"
                                 class="font-light text-xs mr-2 uppercase">
                              {{ day }}
                              <div class="font-semibold text-xs">
                                {{ Group.findMatchDay(group, index) }}
                              </div>
                            </div>

                          </div>
                        </div>


                        <div class="text-center">
                          <div class="font-light text-xs">
                            Свободных мест
                          </div>
                          <div :class="'font-semibold text-'+group.count.color+'-600'">
                            {{ group.count.text }}
                          </div>
                        </div>
                      </div>


                      <div class="grid justify-items-end mt-2">

                        <div v-if="group.count.text!=='мест нет'">
                          <ButtonBlueSm @click="openModalSetGroup(group)">
                            <span class="hidden loading loading-spinner loading-md mr-2"></span>
                            Записаться в группу
                          </ButtonBlueSm>
                        </div>
                        <div v-else>
                          <ButtonRoseSm @click="openModalSetGroup(group)">
                            <span class="hidden loading loading-spinner loading-md mr-2"></span>
                            Записаться в резерв
                          </ButtonRoseSm>
                        </div>

                      </div>


                    </div>


                    <div v-if="!optionsGroups[0]?.id" class="text-center my-6">
                      нет данных
                    </div>

                  </div>


                </div>

                <div v-else class="text-center my-24">
                  Место занятий не выбрано или отсутствуют данные
                </div>

              </transition>
            </div>


          </div>
          <!--end mobile view-->


          <!-- pc view-->
          <div class="lg:block hidden space-y-12">


            <div class="text-center mt-8">
              <div class="tabs w-full place-content-center">
                <a v-for="city in optionsCities"
                   :key="city.id"
                   :class="{'tab-active font-bold':Number(cityId)===Number(city.id)}"
                   class="tab tab-lg tab-lifted bg-accent-content/10 text-black text-base"
                   @click="cityId=city.id">
                  {{ city.name }}
                </a>
              </div>

              <MapPlaces :class="{ 'blur cursor-progress':loadingForm }"
                         :data-coord="dataCoord"
                         class="rounded-xl shadow-lg "
                         @onClickMap="onClickMap"/>

            </div>


            <div>
              <div class=" text-center">
                Выберите филиал
              </div>


              <div class="grid grid-cols-2 xl:grid-cols-3 gap-6 mt-6">


                <div v-for="district in optionsDistrict" :key="district.id"
                     class="rounded-xl shadow-lg p-8 hover text-gray-600 bg-base-100 content-start">

                  <div class="flex self-center text-left ">
                    <div class="font-semibold text-xl text-rose-400">
                      {{ district.name }}
                    </div>
                    <div class="badge badge-ghost text-gray-400 ml-3">
                      Филиалов {{ loadPlacesInDistrict(district.id)?.length ?? optionsPlaces?.length }}
                    </div>
                  </div>

                  <hr class="my-3">

                  <div class="flex items-center">

                    <div class="text-left w-full gap-2">


                      <div v-for="place in loadPlacesInDistrict(district.id) ?? optionsPlaces"
                           :key="place.id">
                        <div class="transition ease-in-out cursor-pointer justify-between
                                   delay-75 hover:-translate-y-1 flex self-center
                                    hover:scale-110 hover:text-[#2e93f9] duration-300"
                             @click="loadGroups(place, '#pcGroups')">

                          <div class="w-3/4">
                            <p class="truncate">{{ place.name }}</p>
                          </div>

                          <div class="badge badge-ghost text-gray-400 ml-3  w-1/4">
                            <p class="truncate">{{ place.type?.name }} {{ place.number }}</p>
                          </div>

                        </div>
                      </div>


                    </div>

                  </div>

                </div>


                <!--            </TransitionGroup>-->

              </div>

            </div>


            <div class="text-left">
              <div id="pcGroups"></div>
              <transition name="bounce">

                <div v-if="placeNow" class="space-y-8 ">

                  <div class="my-12 text-center">
                    Выберите группу
                  </div>

                  <NotifyAddress :place-now="placeNow"/>

                  <div class="text-left">


                    <div class='overflow-auto lg:w-full sm:w-screen w-96 bg-base-100 p-6 rounded-xl shadow-lg'>
                      <table class='table overflow-x-scroll w-full '>
                        <!-- head -->
                        <thead class="">
                        <tr>
                          <th class="w-52">
                            Возрастная категория
                            <div class="font-light">название группы</div>
                          </th>


                          <th v-for="(day, index) in DAY_WEEK"
                              :key="index"
                              class="w-auto text-center uppercase">
                            {{ day }}
                          </th>

                          <th class="w-24 text-center">Стоимость</th>

                          <th class="w-24 text-center">Свободных мест</th>
                          <th class="w-64"></th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="group in optionsGroups"
                            :key="group.id"
                            class="hover">
                          <th>

                            <div>
                              {{ group.ageCategory.name }}
                            </div>
                            <div class="badge badge-outline text-gray-400 font-normal truncate">
                              {{ group.name }}
                            </div>

                          </th>


                          <td v-for="(day, index) in DAY_WEEK"
                              :key="day"
                              class="bg-sky-50/50 text-center">
                            {{ Group.findMatchDay(group, index) }}
                          </td>

                          <td class="text-center font-semibold">
                            {{ (group.price == 0 || group.price == 1) ? '' : group.price }}
                          </td>

                          <td :class="'text-center font-semibold bg-rose-50/50 text-'+group.count.color+'-600'">
                            {{ group.count.text }}
                          </td>

                          <td class="text-orange-600 text-sky-600 text-gray-600 ">

                            <div class="grid justify-end">

                              <div v-if="group.count.text!==NO_PLACES">
                                <ButtonBlueSm @click="openModalSetGroup(group)">
                                  <span class="hidden loading loading-spinner loading-md mr-2"></span>
                                  Записаться в группу
                                </ButtonBlueSm>
                              </div>
                              <div v-else>
                                <ButtonRoseSm @click="openModalSetGroup(group)">
                                  <span class="hidden loading loading-spinner loading-md mr-2"></span>
                                  Записаться в резерв
                                </ButtonRoseSm>
                              </div>

                            </div>


                          </td>
                        </tr>

                        <tr v-if="!optionsGroups[0]?.id" class="text-center my-6">
                          <td colspan="11">
                            нет данных
                          </td>

                        </tr>

                        </tbody>


                      </table>
                    </div>


                  </div>

                </div>

                <div v-else class="text-center my-24">
                  Место занятий не выбрано или отсутствуют данные
                </div>

              </transition>

            </div>


          </div>
          <!--end pc view-->

        </div>


        <ModalDownCenter
            v-if="groupId"
            title="Запишитесь в группу">
          <FormOrder
              :city-id="cityId"
              :group-id="groupId"
              :place-id="placeId"/>
        </ModalDownCenter>


      </div>
      <!--      <div class="w-full h-screen bg-base-100 lg:bg-none">-->


      <!--      </div>-->
    </div>


    <div v-if="loadingForm" class="fixed top-1/2 left-1/2 z-0 text-center">
      <div class="w-56 -left-28 absolute">
        <div class="font-semibold text-xl">
          Загрузка данных
        </div>
        <progress class="progress"></progress>
      </div>
    </div>


  </div>


</template>

<style>
.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

</style>
