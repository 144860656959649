import {ref} from "vue";

export const modalOpen = ref(false);

export function openModal() {
    modalOpen.value = true
    // document.getElementById(idElement).classList.add('modal-open')
}

export function closeModal() {
    modalOpen.value = false
    // document.getElementById(idElement).classList.remove('modal-open')
}
